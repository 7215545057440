import type { GatsbyBrowser } from "gatsby";
import React from "react";
import { WrapPageElement, WrapRootElement } from "./src/gatsby/wrap-element";
import { globalHistory } from "@reach/router";
import { EVENT, EventAggregator } from "@preem/core";
import { ServiceHelper } from "@preem/core";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element, props }) => {
  return <WrapPageElement props={props}>{element}</WrapPageElement>;
};

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  return <WrapRootElement>{element}</WrapRootElement>;
};

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  const API_BASE_URL = process.env.GATSBY_API_BASE_URL;
  if (API_BASE_URL) {
    ServiceHelper.setBaseUrl(API_BASE_URL);
  }

  globalHistory.listen((listener) => {
    EventAggregator.publish(EVENT.HISTORY_ACTION, listener.action);
  });
};

exports.components = {
  "component---src-components-pages-article-page-tsx": () => import("./../../../src/components/pages/article-page.tsx" /* webpackChunkName: "component---src-components-pages-article-page-tsx" */),
  "component---src-components-pages-landing-page-tsx": () => import("./../../../src/components/pages/landing-page.tsx" /* webpackChunkName: "component---src-components-pages-landing-page-tsx" */),
  "component---src-components-pages-news-page-tsx": () => import("./../../../src/components/pages/news-page.tsx" /* webpackChunkName: "component---src-components-pages-news-page-tsx" */),
  "component---src-components-pages-press-and-news-feed-page-press-and-news-feed-page-tsx": () => import("./../../../src/components/pages/press-and-news-feed-page/press-and-news-feed-page.tsx" /* webpackChunkName: "component---src-components-pages-press-and-news-feed-page-press-and-news-feed-page-tsx" */),
  "component---src-components-pages-press-release-page-tsx": () => import("./../../../src/components/pages/press-release-page.tsx" /* webpackChunkName: "component---src-components-pages-press-release-page-tsx" */),
  "component---src-components-pages-search-result-page-tsx": () => import("./../../../src/components/pages/search-result-page.tsx" /* webpackChunkName: "component---src-components-pages-search-result-page-tsx" */),
  "component---src-components-pages-start-page-tsx": () => import("./../../../src/components/pages/start-page.tsx" /* webpackChunkName: "component---src-components-pages-start-page-tsx" */),
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */)
}

